import React from 'react'
import './downloadapp.css'
import logo from '../assets/Logo.png';
import mobile from '../assets/download-btn/mobile.png';
import mobile1 from '../assets/download-btn/phone2.png';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Zoom from "react-reveal/Zoom";

const DownloadApp = () => {
  return (
   <>
   <div style={{position:"relative",overflow: "hidden"}}>
   <div className='text-center maindownloaddiv'>
    <img className='img-fluid' src={logo} alt="logo" style={{height:"25%",width:"25%"}} />
    
   </div>
   <svg className='tri-img' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
	<path class="elementor-shape-fill" d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z" fill="#FFFFFF"></path>
    </svg>
    <Zoom cascade duration={2000}>
   <h1 className='text-center text-white w-100 dwonload-text'>download our app</h1>
   </Zoom>
   </div>
   <div>
    <Zoom cascade duration={2000}>
    <h1 className='ms-3 android-head mb-4'>For android</h1>
    </Zoom>
    <div className='row w-100 mb-5'>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 '>
      <Zoom bottom duration={2000}>
      <p className='ms-3 fs-6 para-text-download'>Get Latest Updates about Our Projects, Meetings, Future plans in Your Mobile. Download Our App Now!</p>
      </Zoom>
      <Zoom bottom duration={2000}>
      <button className='ms-3 down-btn '><AndroidIcon style={{marginRight:".5rem"}}/>Coming Soon</button>
      </Zoom>
      </div>
      <Zoom cascade duration={2000}>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
        <img className='img-fluid mobile-pic' src={mobile} alt="" />
      </div>
      </Zoom>
    </div>
   <div className=' linediv text-center'>
    <div className='line'></div>
   </div>
   </div>
   <div>
    <Zoom cascade duration={2000}>
    <h1 className='ms-3 android-head mb-4'>For ios</h1>
    </Zoom>
    <div className='row w-100 mb-5'>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 '>
        <Zoom bottom duration={2000}>
      <p className='ms-3 fs-6 para-text-download'>Get Latest Updates about Our Projects, Meetings, Future plans in Your Mobile. Download Our App Now!</p>
      </Zoom>
      <Zoom bottom duration={2000}>
      <button className='ms-3 down-btn '><AppleIcon style={{marginRight:".5rem"}}/>Coming Soon</button>
      </Zoom>
      </div>
      <div className='col-12 col-sm-12 col-md-6 col-lg-6 mt-4'>
        <Zoom cascade duration={2000}>
        <img className='img-fluid mobile-pic1 ' src={mobile1} alt="" />
        </Zoom>
      </div>
    </div>
   <div className=' linediv text-center'>
    <div className='line'></div>
   </div>
   </div>

   </>
  )
}

export default DownloadApp