import React, { useState, useEffect } from "react";
import "./funds.css";
import Zoom from "react-reveal/Zoom";

const FundsLahore = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/lahoreFunds`
        );
        if (response.ok) {
          const fetchedData = await response.json();
          setData(fetchedData.allmessages.reverse()); // Use the correct property
        } else {
          console.error("Error fetching data:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []); // Empty dependency array to run once on mount
  const getRowColor = (index) => (index % 2 === 0 ? "white" : "offwhite");
  return (
    <div className="container mt-5 report-bg p-5 mb-5">
      <div>
        <Zoom cascade duration={2000}>
          <h3 className="unit-name text-center">
            Funds Position Of Lahore (Unit)
          </h3>
        </Zoom>
        {data.map((yearData, yearIndex) => (
          <div key={yearIndex}>
            <div
              className="text-center heading-report"
              style={{ borderBottom: "2px solid black", marginLeft: "auto" }}
            >
              <Zoom cascade duration={2000}>
                <strong>{yearData.year}</strong>
              </Zoom>
            </div>
            <figure className="wp-block-table alignfull is-style-stripes">
              <table
                className="table-striped main-table"
                style={{ backgroundColor: "f3f4f5" }}
              >
                {/* Table header */}
                <Zoom right duration={2000}>
                  {" "}
                  <thead>
                    <tr>
                      <th className="has-text-align-center" data-align="center">
                        MONTH
                      </th>
                      <th
                        className="has-text-align-center table-heading"
                        data-align="center"
                      >
                        INCOME
                      </th>
                      <th
                        className="has-text-align-center table-heading"
                        data-align="center"
                      >
                        EXPENSES
                      </th>
                    </tr>
                  </thead>
                </Zoom>
                {/* Table body */}
                <Zoom left duration={2000}>
                  <tbody className="table-body">
                    {yearData.month.map((month, monthIndex) => (
                      <tr key={monthIndex} className={getRowColor(monthIndex)}>
                        <td
                          className="has-text-align-center months"
                          data-align="center"
                        >
                          {month}
                        </td>
                        <td
                          className="has-text-align-center"
                          data-align="center"
                        >
                          {yearData.income[monthIndex]}
                        </td>
                        <td
                          className="has-text-align-center"
                          data-align="center"
                        >
                          {yearData.expenses[monthIndex]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Zoom>
              </table>
            </figure>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FundsLahore;
