import React, { useState, useEffect } from "react";
import "./members.css";
import logo from "../assets/Logo.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Zoom from "react-reveal/Zoom";

const Members = () => {
  const [ourteamData, setOurteamData] = useState([]);
  const [ourRawalteamData, setRawalOurteamData] = useState([]);
  const [ourKhawasteamData, setKhawasOurteamData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/lahoreMembers`
        );
        const data = await response.json();
        setOurteamData(data.sliderData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/rawalpindiMembers`
        );
        const data = await response.json();
        setRawalOurteamData(data.sliderData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/khawasMembers`
        );
        const data = await response.json();
        setKhawasOurteamData(data.sliderData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <Zoom cascade duration={2000}>
          <h1 className="text-center text-white w-100 dwonload-text">
            Our Team
          </h1>
        </Zoom>
      </div>
      <div className="container-fluid mt-5">
        <div className="container member-container rounded ">
          <Zoom cascade duration={2000}>
            <h1 className="text-center pt-5 unit-heading text-white">
              Lahore Unit
            </h1>
          </Zoom>
          <ul className="d-flex memberdetails pt-5 pb-5 m-1">
            {ourteamData.map((item, index) => (
              <Zoom cascade duration={2000}>
                <div
                  className="card imgcard"
                  style={{
                    background: "#343a40",
                    border: "2px solid white",
                    color: "white",
                  }}
                  key={index}
                >
                  <img
                    className="card-img-top images-mem"
                    src={`${process.env.REACT_APP_BASE_URL}/${item.avatar}`}
                    alt="Card cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">{item.name}</h5>
                    <p className="card-text text-center">
                      <h6> {item.designation}</h6>
                    </p>
                  </div>
                  <ul className="list-group list-group-flush text-center">
                    <li
                      className="list-group-item"
                      style={{ background: "#343a40", color: "white" }}
                    >
                      <LocalPhoneIcon />: {item.phone}
                    </li>
                  </ul>
                </div>
              </Zoom>
            ))}
          </ul>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="container member-container rounded ">
          <Zoom cascade duration={2000}>
            <h1 className="text-center pt-5 unit-heading text-white">
              Rawalpindi Unit
            </h1>
          </Zoom>
          <ul className="d-flex memberdetails pt-5 pb-5 m-1 ">
            {ourRawalteamData.map((item, index) => (
              <Zoom cascade duration={2000}>
                <div
                  className="card imgcard "
                  style={{
                    background: "#343a40",
                    border: "2px solid white",
                    color: "white",
                  }}
                  key={index}
                >
                  <img
                    className="card-img-top images-mem"
                    src={`${process.env.REACT_APP_BASE_URL}/${item.avatar}`}
                    alt="Card cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">{item.name}</h5>
                    <p className="card-text text-center">
                      Dessignation: {item.designation}
                    </p>
                  </div>
                  <ul className="list-group list-group-flush text-center">
                    <li
                      className="list-group-item"
                      style={{
                        background: "#343a40",
                        border: "2px solid white",
                        color: "white",
                      }}
                    >
                      <LocalPhoneIcon />: {item.phone}
                    </li>
                  </ul>
                </div>
              </Zoom>
            ))}
          </ul>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="container member-container rounded ">
          <Zoom cascade duration={2000}>
            <h1 className="text-center pt-5 unit-heading text-white">
              Khawas unit
            </h1>
          </Zoom>
          <ul className="d-flex memberdetails pt-5 pb-5 m-1 ">
            {ourKhawasteamData.map((item, index) => (
              <Zoom cascade duration={2000}>
                <div
                  className="card imgcard "
                  style={{
                    background: "#343a40",
                    border: "2px solid white",
                    color: "white",
                  }}
                  key={index}
                >
                  <img
                    className="card-img-top images-mem"
                    src={`${process.env.REACT_APP_BASE_URL}/${item.avatar}`}
                    alt="Card cap"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">{item.name}</h5>
                    <p className="card-text text-center">
                      Dessignation: {item.designation}
                    </p>
                  </div>
                  <ul className="list-group list-group-flush text-center">
                    <li
                      className="list-group-item"
                      style={{
                        background: "#343a40",
                        border: "2px solid white",
                        color: "white",
                      }}
                    >
                      <LocalPhoneIcon />: {item.phone}
                    </li>
                  </ul>
                </div>
              </Zoom>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Members;
