import React from "react";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./footer.css";
import Zoom from "react-reveal/Zoom";
import { useForm } from "react-hook-form";

const Footer = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    reset();
    navigate("/contactus");
  };
  return (
    <>
      <div className="container-fluid  pt-5 " style={{ background: "black" }}>
        <div className="container text-white pt-5">
          <div className="row">
            <Zoom bottom duration={2000}>
              <div className="col-sm-12 col-md-6 col-12">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  enctype="multipart/form-data"
                >
                  <h2 className="mt-3 mb-3 footerheading">Stay up to date</h2>
                  <input
                    type="email"
                    name="email" // Add a name attribute
                    placeholder="Email Address"
                    {...register("email", { required: true })}
                    className="p-2 mt-2  footer-input"
                  />
                  <div style={{ color: "red" }}>
                    {errors.email &&
                      errors.email.type === "required" &&
                      "Email is required *"}
                  </div>
                  <div>
                    <button type="submit" className="mt-3 mb-3 footerbtn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </Zoom>
            <div className="col-sm-12 col-md-6 col-12 ">
              <Zoom right duration={1000}>
                <h2>Head Office Address :</h2>
              </Zoom>
              <Zoom bottom duration={2000}>
                <div>
                  <LocationOnIcon style={{ color: "green" }} />
                  <span className="footeraddr">
                    746/A Dhobi Ghat, Railway Colony , Co-Operative (Co-Op)
                    Store, G.T Road, Lahore.
                  </span>
                </div>
              </Zoom>
              <Zoom right duration={1000}>
                <h2>Branch Office Address :</h2>
              </Zoom>
              <Zoom bottom duration={2000}>
                <div>
                  <LocationOnIcon style={{ color: "green" }} />
                  <span className="footeraddr">
                    House No. CB332/55, Street No. 1, Chaman Abad, Near Jamia
                    Haroonia Masjid, Baraf Khana, Rawalpindi.
                  </span>
                </div>
              </Zoom>
              <Zoom fade-in duration={2000}>
                <div className="mt-5">
                  <span className="socialicons">
                    <a href="https://www.facebook.com/aghazesafarfoundation/">
                      <span
                        className="footersocial"
                        style={{ width: "100%", borderRadius: "50%" }}
                      >
                        <FacebookIcon
                          style={{ height: "50px", width: "80px" }}
                        />
                      </span>
                    </a>
                    <a href="https://youtube.com/@user-fc1xj4gb7f">
                      <span
                        className=""
                        style={{ width: "100%", borderRadius: "50%" }}
                      >
                        <YouTubeIcon
                          style={{ height: "50px", width: "80px" }}
                        />
                      </span>
                    </a>
                    <a
                      class="elementor-icon elementor-social-icon elementor-social-icon- elementor-repeater-item-3681267"
                      href="mailto:contact@aghazesafarfoundation.com?cc=aghazsafar.foundation.com&amp;subject=Contact&amp;AGHAZ-E-SAFAR%20FOUNDATION%20®"
                      target="_blank"
                    >
                      <span class="elementor-screen-only"></span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="50px"
                        height="50px"
                      >
                        <path
                          fill="#e0e0e0"
                          d="M5.5,40.5h37c1.933,0,3.5-1.567,3.5-3.5V11c0-1.933-1.567-3.5-3.5-3.5h-37C3.567,7.5,2,9.067,2,11v26C2,38.933,3.567,40.5,5.5,40.5z"
                        ></path>
                        <path
                          fill="#d9d9d9"
                          d="M26,40.5h16.5c1.933,0,3.5-1.567,3.5-3.5V11c0-1.933-1.567-3.5-3.5-3.5h-37C3.567,7.5,2,9.067,2,11L26,40.5z"
                        ></path>
                        <path
                          fill="#eee"
                          d="M6.745,40.5H42.5c1.933,0,3.5-1.567,3.5-3.5V11.5L6.745,40.5z"
                        ></path>
                        <path
                          fill="#e0e0e0"
                          d="M25.745,40.5H42.5c1.933,0,3.5-1.567,3.5-3.5V11.5L18.771,31.616L25.745,40.5z"
                        ></path>
                        <path
                          fill="#ca3737"
                          d="M42.5,9.5h-37C3.567,9.5,2,9.067,2,11v26c0,1.933,1.567,3.5,3.5,3.5H7V12h34v28.5h1.5c1.933,0,3.5-1.567,3.5-3.5V11C46,9.067,44.433,9.5,42.5,9.5z"
                        ></path>
                        <path
                          fill="#f5f5f5"
                          d="M42.5,7.5H24H5.5C3.567,7.5,2,9.036,2,11c0,1.206,1.518,2.258,1.518,2.258L24,27.756l20.482-14.497c0,0,1.518-1.053,1.518-2.258C46,9.036,44.433,7.5,42.5,7.5z"
                        ></path>
                        <path
                          fill="#e84f4b"
                          d="M43.246,7.582L24,21L4.754,7.582C3.18,7.919,2,9.297,2,11c0,1.206,1.518,2.258,1.518,2.258L24,27.756l20.482-14.497c0,0,1.518-1.053,1.518-2.258C46,9.297,44.82,7.919,43.246,7.582z"
                        ></path>
                      </svg>{" "}
                    </a>
                  </span>
                </div>
              </Zoom>
            </div>
            <Zoom fade-in duration={2000}>
              <div className="col-sm-12 mt-5">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.1469334508943!2d74.34687927408358!3d31.575018344442846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191b32b8b5f619%3A0xcb9d418b6742d6c4!2sAghaz%20e%20Safar%20Foundation!5e0!3m2!1sen!2s!4v1685468350656!5m2!1sen!2s"
                  style={{
                    width: "90vw",
                    height: "70vh",
                    border: "none",
                    allowfullscreen: "",
                    loading: "lazy",
                  }}
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Zoom>
            <Zoom left duration={2000}>
              <div className="col-md-12">
                <h5 className="text-center m-5">
                  Copyright © 2024 aghaz-e-safer foundation Pakistan
                </h5>
              </div>
            </Zoom>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
