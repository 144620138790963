import React, { useState, useEffect } from "react";
import "./meetingSlider.css";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link } from "react-scroll";
import Zoom from "react-reveal/Zoom";
import swal from "sweetalert2";

const MeetingSlider = () => {
  const navigate = useNavigate();
  const { index } = useParams(); // Get the index from the URL parameter
  const initialSlideIndex = parseInt(index);
  const [currentSlide, setCurrentSlide] = useState(initialSlideIndex);
  const [ourteamData, setOurteamData] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("website", data.website);
      formData.append("email", data.email);
      formData.append("message", data.message);

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/meetingComment`,
        {
          method: "post",
          body: formData,
        }
      );
      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
      result = await result.json();
      swal.fire({
        position: "center",
        icon: "success",
        title: "Comment is sent!",
        showConfirmButton: true,
        timer: 5000,
      });
      reset();
      navigate("/");
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/meetingdetails`
        );
        const data = await response.json();
        setOurteamData(data.sliderData.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  const handleNextSlide = () => {
    const nextSlide = (currentSlide + 1) % ourteamData.length;
    setCurrentSlide(nextSlide);
    navigate(`/meetingSlider/${nextSlide}`);
  };
  const handlePreviousSlide = () => {
    const previousSlide =
      currentSlide === 0 ? ourteamData.length - 1 : currentSlide - 1;
    setCurrentSlide(previousSlide);
    navigate(`/meetingSlider/${previousSlide}`);
  };
  const searchHandle = async (event) => {
    const searchValue = event.target.value;
    if (searchValue) {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_BASE_URL}/meetingdetails/search/${searchValue}`
        );
        result = await result.json();
        if (result) {
          setOurteamData(result);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/meetingdetails`
        );
        const data = await response.json();
        setOurteamData(data.sliderData.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  const openLightbox = (index) => {
    setLightboxOpen(true);
    setSelectedImageIndex(index);
  };
  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImageIndex(null);
  };
  const navigateImage = (direction) => {
    if (selectedImageIndex !== null) {
      let newIndex;
      if (direction === "next") {
        newIndex =
          (selectedImageIndex + 1) % ourteamData[currentSlide].avatar.length;
      } else {
        newIndex =
          (selectedImageIndex - 1 + ourteamData[currentSlide].avatar.length) %
          ourteamData[currentSlide].avatar.length;
      }
      setSelectedImageIndex(newIndex);
    }
  };
  return (
    <>
      <div className="text-center m-5 search-container">
        <input
          type="input"
          className="search-input"
          placeholder="Search by unit name like(lahore)..."
          onChange={searchHandle}
        />
        <span className="search-icon">&#128269;</span>
      </div>
      {ourteamData.length > 0 && ourteamData[currentSlide] ? (
        <div className="container slidermaindiv mt-5 mb-2">
          <div className="slider pt-5 ps-5" style={{ height: "auto" }}>
            <h2>{ourteamData[currentSlide].title}</h2>
            <div>
              <Link
                to="sliderform"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                style={{
                  color: "green",
                  textDecoration: "underline",
                  marginRight: "1rem",
                }}
              >
                Leave a Comment
              </Link>
              <a
                href="/"
                style={{ color: "green", textDecoration: "underline" }}
              >
                Aghaz-e-Safar Foundation
              </a>
            </div>

            <h6 style={{ color: "green", cursor: "pointer" }}>
              {ourteamData[currentSlide].unit}
            </h6>
            <div className="images-container">
              {ourteamData[currentSlide].avatar.map((imageURL, index) => (
                <Zoom key={index} cascade duration={2000}>
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${imageURL}`}
                    alt={`Image ${index + 1}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => openLightbox(index)}
                  />
                </Zoom>
              ))}

              {lightboxOpen && selectedImageIndex !== null && (
                <div className="lightbox-overlay" onClick={closeLightbox}>
                  <div className="lightbox-content">
                    <div className="image-counter">
                      {selectedImageIndex + 1} /{" "}
                      {ourteamData[currentSlide].avatar.length}
                    </div>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${ourteamData[currentSlide].avatar[selectedImageIndex]}`}
                      alt={`Image ${selectedImageIndex + 1}`}
                    />
                    <span className="close-button" onClick={closeLightbox}>
                      &times;
                    </span>
                    <span
                      className="nav-button left"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateImage("prev");
                      }}
                    >
                      &#8249;
                    </span>
                    <span
                      className="nav-button right"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateImage("next");
                      }}
                    >
                      &#8250;
                    </span>
                  </div>
                </div>
              )}
            </div>

            <p className="slider-txt">{ourteamData[currentSlide].text}</p>
          </div>
        </div>
      ) : (
        <>
          <h1 className="text-center" style={{ color: "red" }}>
            Found No Result
          </h1>
        </>
      )}
      <div className=" container p-3 buttons-Slider">
        <button onClick={handlePreviousSlide}>
          <ArrowBackIosNewIcon style={{ marginRight: ".5rem" }} />
          Previous
        </button>
        <button onClick={handleNextSlide}>
          Next
          <ArrowForwardIosIcon style={{ marginLeft: ".5rem" }} />
        </button>
      </div>
      {/* *************comments Section************** */}
      <div
        className="container slidermaindiv mt-5 mb-2 mb-5 pb-5"
        id="sliderform"
      >
        <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
          <h1 className="pt-5 ps-5 ">Leave a Comment</h1>
          <p className="ps-5">
            Your email address will not be published. Required fields are marked{" "}
            <span style={{ color: "red" }}>*</span>
          </p>
          <textarea
            className="text-area-comments"
            name="comment" // Add a name attribute
            placeholder="Type here..."
            {...register("comment", { required: true })} // Register the input
          />
          <span style={{ marginLeft: "3rem", color: "red" }}>
            {errors.comment &&
              errors.comment.type === "required" &&
              "Comment is required *"}
          </span>
          <div className=" container row mb-3">
            <div className="col-sm-12 col-md-4 col-lg-4 col-12">
              <input
                name="name" // Add a name attribute
                placeholder="Name*"
                {...register("name", { required: true })}
                className=" ms-5 mt-3  inputs-all-comments"
                type="text"
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.name &&
                  errors.name.type === "required" &&
                  "name is required *"}
              </span>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-12">
              <input
                className=" ms-5 mt-3 inputs-all-comments"
                type="email"
                placeholder="Email*"
                name="email" // Add a name attribute
                {...register("email", { required: true })}
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.email &&
                  errors.email.type === "required" &&
                  "email is required *"}
              </span>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-12">
              <input
                className=" ms-5 mt-3 inputs-all-comments"
                type="text"
                placeholder="Website*"
                name="website" // Add a name attribute
                {...register("website", { required: true })}
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.website &&
                  errors.website.type === "required" &&
                  "website is required *"}
              </span>
            </div>
          </div>
          <div className="ms-5">
            <input
              type="checkbox"
              style={{ height: "1rem", width: "1rem" }}
              {...register("saveInfo", { required: true })}
            />
            <span className="ms-2" style={{ width: "70%" }}>
              Save my name, email, and website in this browser for the next time
              I comment.
            </span>
            <span style={{ marginLeft: "3rem", color: "red" }}>
              {errors.saveInfo && "You must agree to save information."}
            </span>
          </div>
          <button type="submit" className="post-comment">
            post comment
            <NavigateNextIcon style={{ marginTop: "-.3rem" }} />
            <NavigateNextIcon
              style={{ position: "absolute", marginLeft: "-1rem" }}
            />
          </button>
        </form>
      </div>
    </>
  );
};

export default MeetingSlider;
