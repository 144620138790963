import React, { useRef } from "react";
import "./donate.css";
import logo from "../assets/Logo.png";
import video from "../assets/donateus/project-video.mp4";
import pic from "../assets/donateus/edit 1.png";
import pic2 from "../assets/donateus/edit 2.png";
import Zoom from "react-reveal/Zoom";

const DonateUs = () => {
  const videoRef = useRef(null);

  const handleVideoEnded = () => {
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };
  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <Zoom cascade duration={2000}>
          <h1 className="text-center text-white w-100 dwonload-text">
            donate us
          </h1>
        </Zoom>
      </div>
      <div></div>
      <div class="donate-video text-center mt-2">
        <video
          ref={videoRef}
          className="elementor-video video-donation"
          src={video}
          autoPlay
          muted
          playsInline
          controlsList="nodownload"
          onEnded={handleVideoEnded}
        ></video>
      </div>
      <div className="row text-center w-100 ps-3 mt-5 mb-2">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
          <Zoom cascade duration={2000}>
            <img className="dona-1-img img-fluid" src={pic} alt="" />
          </Zoom>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
          <Zoom cascade duration={2000}>
            <img className="dona-1-img img-fluid" src={pic2} alt="" />
          </Zoom>
        </div>
      </div>
    </>
  );
};

export default DonateUs;
