import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route,  } from 'react-router-dom';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Aboutus from './components/Aboutus';
import Home from './components/Home';
import Members from './components/Members';
import Contactus from './components/Contactus';
import DownloadApp from './components/DownloadApp';
import FutureProjects from './components/FutureProjects';
import DonateUs from './components/DonateUs';
import FundsLahore from './components/FundsLahore';
import FundsRawalpindi from './components/FundsRawalpindi';
import Audits from './components/Audits';
import PerformanceReport from './components/PerformanceReport'
import Registration from './components/Registration';
import Suggestion from './components/Suggestion';
import Meetings from './components/Meetings';
import MeetingSlider from './components/MeetingSlider';
import Project from './components/Project';
import ProjectSlider from './components/ProjectSlider';

function App() {
  return (
    <>
   <BrowserRouter>
   <Navbar/>
  <Routes>
    <Route path='/'  element={<Home/>}/>
     <Route path='/ourteam' element={<Members/>}/>
     <Route path='/contactus' element={<Contactus/>}/>
     <Route path='/main-idea' element={<Aboutus/>}/>
    <Route path='/performance-lahore' element={<PerformanceReport/>}/>
    <Route path='/audit' element={<Audits/>}/>
    <Route path='/download-app' element={<DownloadApp/>}/>
    <Route path='/funds-lahore' element={<FundsLahore/>}/>
    <Route path='/funds-rawalpindi' element={<FundsRawalpindi/>}/>
    <Route path='/future-projct' element={<FutureProjects/>}/>
    <Route path='/donate-us' element={<DonateUs/>}/>
    <Route path='/join-us' element={<Registration/>}/>
    <Route path='/suggestion' element={<Suggestion/>}/>
    <Route path='/meetings' element={<Meetings cardsPerPage={12} />}/>
    <Route path='/projects' element={<Project cardsPerPage={12}/>}/>
    <Route path='/meetingSlider/:index' element={<MeetingSlider/>}/>
    <Route path='/projectSlider/:index' element={<ProjectSlider/>}/>
    
  </Routes>
  <Footer/>
</BrowserRouter>
    
    </>
  );
}

export default App;
