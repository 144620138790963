import React, { useState, useEffect } from "react";
import "./audits.css";
import logo from "../assets/Logo.png";
import CloseIcon from "@mui/icons-material/Close";
import imgdisplay from "../assets/Documents/auditReport.jpg";
import imgpdf from "../assets/Documents/certificate.jpg";
import Zoom from "react-reveal/Zoom";

const Audits = () => {
  const [openImageIndex, setOpenImageIndex] = useState(-1); // Separate state for images
  const [openPdfIndex, setOpenPdfIndex] = useState(-1); // Separate state for PDFs
  const [ourteamData, setOurteamData] = useState([]);
  const [pdf, setPdf] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/auditImages`
        );
        const data = await response.json();
        setOurteamData(data.MeetingsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/auditPdf`
        );
        const data = await response.json();
        setPdf(data.MeetingsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);
  const handleImageClick = (index) => {
    setOpenImageIndex(index);
    setOpenPdfIndex(-1); // Close any open PDF when an image is clicked
  };

  const handlePdfClick = (index) => {
    setOpenPdfIndex(index);
    setOpenImageIndex(-1); // Close any open image when a PDF is clicked
  };

  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <Zoom cascade duration={2000}>
          <h1 className="text-center text-white w-100 dwonload-text">
            certificates & audits
          </h1>
        </Zoom>
      </div>
      <ul className="audits">
        {ourteamData.map((item, index) => (
          <div key={index} className="audit-item">
            {openImageIndex === index ? (
              <div className="pdf-container">
                <button
                  className="crossbtn"
                  title="Close"
                  style={{ color: "red", background: "black" }}
                  onClick={() => handleImageClick(-1)}
                >
                  <CloseIcon />
                </button>
                <Zoom cascade duration={2000}>
                  <img
                    style={{ width: "90%", height: "auto" }}
                    src={`${process.env.REACT_APP_BASE_URL}/${item.avatar}`}
                    alt=""
                  />
                </Zoom>
              </div>
            ) : (
              <div className="card-container">
                <div className="card">
                  <img
                    className="card-img-top img-fluid"
                    src={imgdisplay}
                    alt="Card cap"
                  />
                  <div className="card-body bg-dark">
                    <h5 className="card-title" style={{ color: "white" }}>
                      {item.title}
                    </h5>
                    <button
                      className="btn btn-success text-white fs-4 rounded"
                      onClick={() => handleImageClick(index)}
                    >
                      Open Image
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </ul>

      <ul className="audits">
        {pdf.map((item, index) => (
          <Zoom cascade duration={2000}>
            <div key={index} className="audit-item">
              {openPdfIndex === index ? (
                <div className="pdf-container">
                  <button
                    className="crossbtn"
                    title="Close"
                    onClick={() => handlePdfClick(-1)}
                  >
                    <CloseIcon />
                  </button>
                  <iframe
                    src={`${process.env.REACT_APP_BASE_URL}/${item.pdf}?igu=1`}
                    title="PDF Viewer"
                    className="responsive-iframe"
                  ></iframe>
                </div>
              ) : (
                <div className="card-container">
                  <div className="card">
                    <img
                      className="card-img-top img-fluid"
                      src={imgpdf}
                      alt="Card cap"
                    />
                    <div className="card-body bg-dark">
                      <h5 className="card-title" style={{ color: "white" }}>
                        {item.title}
                      </h5>
                      <button
                        className="btn btn-success text-white fs-4 rounded"
                        onClick={() => handlePdfClick(index)}
                      >
                        Open PDF
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Zoom>
        ))}
      </ul>
    </>
  );
};

export default Audits;
