import React, { useState, useEffect } from "react";
import "./ourteam.css";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../assets/Logo.png";
const OurTeam = () => {
  const [selected, setSelected] = useState(0);
  const [ourteamData, setOurteamData] = useState([]);
  const tLength = ourteamData.length;
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/message`
        );
        const data = await response.json();
        setOurteamData(data.allmessages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    // Auto-play the carousel every 3 seconds
    const interval = setInterval(() => {
      setSelected((prevIndex) =>
        prevIndex === tLength - 1 ? 0 : prevIndex + 1
      );
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, [tLength]);

  return (
    <>
      <div className="container-fluid">
        <div className="container mb-5 bg-dark rounded">
          <div className="row p-4">
            <div
              className="col-sm-12 col-md-6 col-lg-6 col-12 pb-5 img-div"
              style={{ position: "relative" }}
            >
              <div></div>
              <div></div>
              <div>
                {ourteamData.length > 0 && ourteamData[selected] && (
                  <img
                    className="img-fluid images-members text-center"
                    src={`${process.env.REACT_APP_BASE_URL}/${ourteamData[selected].avatar}`}
                    alt=""
                  />
                )}
              </div>
              <div className="mt-5 p-3 text-center w-80 g-4">
                <ArrowBackIcon
                  onClick={() => {
                    setSelected((prev) =>
                      prev === 0 ? tLength - 1 : prev - 1
                    );
                  }}
                  style={{
                    height: "2rem",
                    width: "2rem",
                    color: "white",
                    marginLeft: "5%",
                    cursor: "pointer",
                  }}
                />
                <ArrowForwardIcon
                  onClick={() => {
                    setSelected((next) =>
                      next === tLength - 1 ? 0 : next + 1
                    );
                  }}
                  style={{
                    height: "2rem",
                    width: "2rem",
                    color: "white",
                    marginLeft: "5%",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={selected}
                className="col-sm-12 col-md-6 col-lg-6 col-12"
              >
                <img
                  src={logo}
                  style={{ width: "4rem", height: "4rem" }}
                  alt="logo"
                />
                {ourteamData.length > 0 &&
                  selected >= 0 &&
                  selected < tLength && (
                    <>
                      <motion.span
                        className="logo-title"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 5, delay: 0.5 }}
                      >
                        Aghaz-e-safar foundation <sup>©</sup>
                      </motion.span>
                      <motion.h1
                        className="m-name"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 5, delay: 1 }}
                      >
                        {ourteamData[selected].name}
                      </motion.h1>
                      <motion.p
                        className="designation"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 5, delay: 1.5 }}
                      >
                        {ourteamData[selected].designation}
                      </motion.p>
                      <motion.p
                        className="description"
                        initial={{ y: 50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 5, delay: 2 }}
                      >
                        {ourteamData[selected].message}
                      </motion.p>
                    </>
                  )}
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
