import React from "react";
import "./suggestion.css";
import { useForm } from "react-hook-form";
import logo from "../assets/Logo.png";
import Zoom from "react-reveal/Zoom";
import swal from "sweetalert2";
const Suggestion = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch, // Add watch from react-hook-form
  } = useForm();
  const selectedUnit = watch("unit"); // Watch the selected radio button
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("fname", data.fname);
      formData.append("lname", data.lname);
      formData.append("email", data.email);
      formData.append("branch", data.branch);
      formData.append("message", data.message);
      let result = await fetch(`${process.env.REACT_APP_BASE_URL}/suggestion`, {
        method: "post",
        body: formData,
      });
      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
      result = await result.json();
      swal.fire({
        position: "center",
        icon: "success",
        title: "Suggestion is sent!",
        showConfirmButton: true,
        timer: 5000,
      });
      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <Zoom cascade duration={2000}>
          <h1 className="text-center text-white w-100 dwonload-text">
            suggestions
          </h1>
        </Zoom>
      </div>
      <div className="container suggestion-div mt-5 mb-5">
        <Zoom bottom duration={2000}>
          <div className="container">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="pb-5 pt-5"
              action=""
              enctype="multipart/form-data"
            >
              <h3 className="text-center mb-3 heading-suggestion">
                Give us a Suggesstion
              </h3>
              <label className="label-input" htmlFor="">
                First Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="fname" // Add a name attribute
                placeholder="First Name"
                {...register("fname", { required: true })}
                className="input-fields-suggestion"
                type="text"
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.fname &&
                  errors.fname.type === "required" &&
                  "First Name is required *"}
              </span>
              <label className="label-input" htmlFor="">
                Last Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="lname" // Add a name attribute
                placeholder="Last Name"
                {...register("lname", { required: true })}
                className="input-fields-suggestion"
                type="text"
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.lname &&
                  errors.lname.type === "required" &&
                  "Last Name is required *"}
              </span>
              <label className="label-input" htmlFor="">
                Email<span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="email" // Add a name attribute
                placeholder="E-mail"
                {...register("email", { required: true })}
                className="input-fields-suggestion"
                type="text"
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.email &&
                  errors.email.type === "required" &&
                  "Email is required *"}
              </span>
              <label className="label-input" htmlFor="">
                Which branch do you have a suggestion for?
                <span style={{ color: "red" }}>*</span>
              </label>
              <input
                name="branch"
                type="radio"
                value="lahore"
                className="radio-input-btn"
                {...register("branch", { required: true })}
              />
              <span className="radio-txt">Lahore</span>
              <br />
              <input
                name="branch"
                type="radio"
                value="rawalpindi"
                className="radio-input-btn"
                {...register("branch", { required: true })}
              />
              <span className="radio-txt">Rawalpindi(unit)</span>
              <br />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {!selectedUnit && errors.branch && "Please select a branch"}
              </span>
              <label className="label-input" htmlFor="">
                Message<span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                name="message" // Add a name attribute
                placeholder="Any Suggestion...."
                {...register("message", { required: true })}
                className="suggestion-textarea"
                type="text"
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.message &&
                  errors.message.type === "required" &&
                  "Suggestion is required *"}
              </span>
              <div className="text-center mt-5">
                <button type="submit" className="submit-suggestion">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Zoom>
      </div>
    </>
  );
};

export default Suggestion;
