import React, { useState } from "react";
import "./registration.css";
import { useNavigate } from "react-router-dom";
import logo from "../assets/Logo.png";
import { useForm } from "react-hook-form";
import Zoom from "react-reveal/Zoom";
import swal from "sweetalert2";

const Registration = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("fname", data.fname);
      formData.append("lname", data.lname);
      formData.append("r_address", data.raddress);
      formData.append("p_address", data.paddress);
      formData.append("cnic", data.cnic);
      formData.append("education", data.education);
      formData.append("profession", data.profession);
      formData.append("blood_group", data.bloodgroup);
      formData.append("phone", data.phone);
      formData.append("issue_date", data.issue_date);
      if (data.avatar && data.avatar[0]) {
        formData.append("avatar", data.avatar[0]);
      }
      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/registration`,
        {
          method: "post",
          body: formData,
        }
      );
      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
      result = await result.json();
      swal.fire({
        position: "center",
        icon: "success",
        title: "Registration is successfully!",
        showConfirmButton: true,
        timer: 5000,
      });
      reset();
      navigate("/");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error, show an error message to the user, etc.
    }
  };
  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <Zoom cascade duration={2000}>
          <h1 className="text-center text-white w-100 dwonload-text">
            registration
          </h1>
        </Zoom>
      </div>
      <div className="container ">
        <div className="container formDiv mt-5 mb-5 pb-5">
          <Zoom bottom duration={2000}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              enctype="multipart/form-data"
            >
              <label className="labels-name" htmlFor="">
                Name
              </label>
              <input
                name="fname" // Add a name attribute
                placeholder="E.g. ABC"
                {...register("fname", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.fname &&
                  errors.fname.type === "required" &&
                  "Name is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Father/Husband
              </label>
              <input
                name="lname" // Add a name attribute
                placeholder="E.g. DEFG"
                {...register("lname", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.lname &&
                  errors.lname.type === "required" &&
                  "Father/Husband Name is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Residential Address
              </label>
              <input
                name="raddress" // Add a name attribute
                placeholder="E.g. 786/A Dhobi Ghat, Railway Colony, Co-Operative Store, G.T Road, Lahore."
                {...register("raddress", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.raddress &&
                  errors.raddress.type === "required" &&
                  "Residential Address is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Permanent Address
              </label>
              <input
                name="paddress" // Add a name attribute
                placeholder="E.g. 786/A Dhobi Ghat, Railway Colony, Co-Operative Store, G.T Road, Lahore."
                {...register("paddress", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.paddress &&
                  errors.paddress.type === "required" &&
                  "Permanent Address is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                CNIC No.
              </label>
              <input
                name="cnic" // Add a name attribute
                placeholder="E.g. 82101000000001"
                {...register("cnic", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.cnic &&
                  errors.cnic.type === "required" &&
                  "CNIC is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Education
              </label>
              <input
                name="education" // Add a name attribute
                placeholder="E.g. Matric "
                {...register("education", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.education &&
                  errors.education.type === "required" &&
                  "Education is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Profession
              </label>
              <input
                name="profession" // Add a name attribute
                placeholder="E.g. Job/Student"
                {...register("profession", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.profession &&
                  errors.profession.type === "required" &&
                  "Profession is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Blood Group
              </label>
              <select
                className="input-fields"
                id="bloodGroup"
                name="bloodgroup"
                {...register("bloodgroup", { required: true })}
              >
                <option className="blood-options" value="A+">
                  A<sup>+</sup>
                </option>
                <option className="blood-options" value="A-">
                  A<sup>-</sup>
                </option>
                <option className="blood-options" value="B+">
                  B<sup>+</sup>
                </option>
                <option className="blood-options" value="B-">
                  B<sup>-</sup>
                </option>
                <option className="blood-options" value="AB+">
                  AB<sup>+</sup>
                </option>
                <option className="blood-options" value="AB-">
                  AB<sup>-</sup>
                </option>
                <option className="blood-options" value="O+">
                  O<sup>+</sup>
                </option>
                <option className="blood-options" value="O-">
                  O<sup>-</sup>
                </option>
              </select>
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.bloodgroup &&
                  errors.bloodgroup.type === "required" &&
                  "Blood Group is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Phone Number
              </label>
              <input
                name="phone" // Add a name attribute
                placeholder="E.g. 00000000000"
                {...register("phone", { required: true })}
                className="input-fields"
                type="text"
              />
              <br />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.phone &&
                  errors.phone.type === "required" &&
                  "Phone number is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Upload Singnature
              </label>
              <input
                type="file"
                name="avatar"
                className="input-fields"
                {...register("avatar", { required: true })}
              />
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.avatar &&
                  errors.avatar.type === "required" &&
                  "Please Select image *"}
              </span>
              <label className="all-labels" htmlFor="">
                Issue Date
              </label>
              <input
                name="issue_date" // Add a name attribute
                {...register("issue_date", { required: true })}
                className="input-fields"
                type="date"
              />
              <div style={{ color: "red", marginLeft: "3rem" }}>
                {errors.issue_date &&
                  errors.issue_date.type === "required" &&
                  "Date is required *"}
              </div>
              <label className="all-labels" htmlFor="">
                Read This and Agree to this Before Submitting the Form{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
              <div className="checkbtn">
                <input
                  type="checkbox"
                  style={{ height: "5rem", width: "5rem" }}
                  {...register("saveInfo", { required: true })}
                />
                <div
                  className={`checkbox-indicator ${isChecked ? "checked" : ""}`}
                />
                <p>
                  I meet the requirements of the Aghaz-e-Safar Foundation’s
                  constitution. I have seriously studied the constitution of the
                  Aghaz-e-Safar Foundation, especially the ideas and aims and
                  objectives, the terms of membership, the ethics for the
                  members and the rights and duties. I fully agree with them and
                  will abide by the Constitution of the Foundation and the
                  decisions, policies, programs, strategies and organizational
                  discipline of the Center.And I also pledge to Allah Almighty
                  that I will not use the membership of the organization for
                  personal purposes.I request to be a member of Aghaz-e-Safar
                  Foundation
                </p>
              </div>
              <span style={{ marginLeft: "3rem", color: "red" }}>
                {errors.saveInfo && "You must agree to save information."}
              </span>
              <div className="text-center">
                <button type="submit" className="submit-button text-center">
                  Submit
                </button>
              </div>
            </form>
          </Zoom>
        </div>
      </div>
    </>
  );
};

export default Registration;
