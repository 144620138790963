import React, { useState, useEffect } from "react";
import "./performance-report.css";
import Zoom from "react-reveal/Zoom";
const PerformanceReport = () => {
  const [ourteamData, setOurteamData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/performancereport`
        );
        const data = await response.json();
        setOurteamData(data.sliderData.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      {Array.isArray(ourteamData) && ourteamData.length > 0 ? (
        ourteamData.map((data, index) => (
          <div className="container mt-5 report-bg p-5 mb-4" key={index}>
            <div>
              <Zoom cascade duration={2000}>
                <h3 className="unit-name">{data.unit}</h3>
              </Zoom>
              <Zoom right duration={2000}>
                <div className="text-center  heading-report">
                  <strong>{data.title}</strong>
                </div>
              </Zoom>
              <Zoom cascade duration={2000}>
                <img
                  className="img-fluid performances-report-img"
                  src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
                  alt="performance"
                />
              </Zoom>
            </div>
          </div>
        ))
      ) : (
        <>
          <p>No messages available.</p>
          <p>May be API error</p>
          <p>No Data</p>
        </>
      )}
    </>
  );
};

export default PerformanceReport;
