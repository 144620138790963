import React from "react";
import "./contactus.css";
import logo from "../assets/Logo.png";
import { useForm } from "react-hook-form";
import Zoom from "react-reveal/Zoom";
import swal from "sweetalert2";

const Contactus = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("fname", data.fname);
      formData.append("lname", data.lname);
      formData.append("email", data.email);
      formData.append("message", data.message);

      let result = await fetch(
        `${process.env.REACT_APP_BASE_URL}/contactusUsers`,
        {
          method: "post",
          body: formData,
        }
      );

      if (!result.ok) {
        throw new Error("Network response was not ok");
      }
      result = await result.json();
      swal.fire({
        position: "center",
        icon: "success",
        title: "Message is sent!",
        showConfirmButton: true,
        timer: 5000,
      });
      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <>
      <div className="container-fluid bg-dark p-4">
        <div className="container">
          <div className="row">
            <Zoom bottom duration={2000}>
              <div className="col-sm-12 col-md-6 col-12 text-center">
                <h1 className="text-white mt-5 contacthead">Message Us</h1>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  enctype="multipart/form-data"
                >
                  <input
                    name="fname" // Add a name attribute
                    placeholder="First Name"
                    {...register("fname", { required: true })}
                    className="inputname my-4"
                  />
                  <div style={{ color: "red" }}>
                    {errors.fname &&
                      errors.fname.type === "required" &&
                      "First Name is required *"}
                  </div>
                  <input
                    name="lname" // Add a name attribute
                    placeholder="Last Name"
                    {...register("lname", { required: true })}
                    className="inputname my-4"
                  />
                  <div style={{ color: "red" }}>
                    {errors.lname &&
                      errors.lname.type === "required" &&
                      "Last Name is required *"}
                  </div>
                  <input
                    name="email" // Add a name attribute
                    placeholder="E-mail"
                    {...register("email", { required: true })}
                    className="inputname my-4"
                  />
                  <div style={{ color: "red" }}>
                    {errors.email &&
                      errors.email.type === "required" &&
                      "Email is required *"}
                  </div>
                  <textarea
                    name="message" // Add a name attribute
                    placeholder="Any Message...."
                    type="text"
                    {...register("message", { required: true })}
                    className="my-4"
                    style={{
                      height: "100px",
                      width: "70%",
                      padding: "20px",
                      borderRadius: "6px",
                      outline: "none",
                      color: "green",
                    }}
                  />
                  <div style={{ color: "red" }}>
                    {errors.message &&
                      errors.message.type === "required" &&
                      "message is required *"}
                  </div>
                  <button type="submit" className="contactbtn">
                    Submit
                  </button>
                </form>
              </div>
            </Zoom>

            <div className="col-sm-12 col-md-6 ">
              <Zoom bottom duration={2000}>
                <h1 className="text-white mt-5 contacthead text-center">
                  Get in Touch
                </h1>
                <div className="mt-5">
                  <h3 className="allEmails">Office Email :</h3>
                  <p className="mailsall text-center">
                    contact@aghazesafarfoundation.com
                  </p>
                  <h3 className="allEmails">Chairman Email :</h3>
                  <p className="mailsall text-center">
                    chairman@aghazesafarfoundation.com
                  </p>
                  <h3 className="allEmails">President Lahore Email :</h3>
                  <p className="mailsall text-center">
                    presidentlhr@aghazesafarfoundation.com
                  </p>
                  <h3 className="allEmails">
                    President Rawalpindi (unit) Email :
                  </h3>
                  <p className="mailsall text-center">
                    presidentrwp@aghazesafarfoundation.com
                  </p>
                </div>
              </Zoom>

              <Zoom cascade duration={2000}>
                <img className=" conlogo" src={logo} alt="" />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
