import React, { useEffect, useState } from "react";
import "./home.css";
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import donate from "../assets/donate.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDollarToSlot } from "@fortawesome/free-solid-svg-icons";
import ambulance from "../assets/ambulance.jpg";
import city from "../assets/homepics/city-distric.png";
import punjab from "../assets/homepics/punjab(logo).png";
import unity from "../assets/homepics/unity.png";
import OurTeam from "./OurTeam";
import Zoom from "react-reveal/Zoom";
import { useNavigate } from "react-router-dom";
const AutoSlider = () => {
  const animationControls = useAnimation();
  const onscrolls = useAnimation();
  const onscrollgreen = useAnimation();
  const [slides, setSliderData] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const [inView] = useInView({
    triggerOnce: true, // Only trigger animation once when it comes into view
  });
  const [progresGreensRef, progressGreenInView] = useInView({
    triggerOnce: true, // Only trigger animation once when it comes into view
  });
  const [progressRef, progressInView] = useInView({
    triggerOnce: true, // Only trigger animation once when it comes into view
  });
  useEffect(() => {
    if (inView) {
      // When the element is in view, trigger the animation
      animationControls.start({ opacity: 1, y: 0 });
    }
  }, [inView, animationControls]);
  useEffect(() => {
    if (progressGreenInView) {
      // When the element is in view, trigger the width animation
      onscrollgreen.start({ width: "10%" });
    }
  }, [progressGreenInView, onscrolls]);
  useEffect(() => {
    if (progressInView) {
      // When the element is in view, trigger the width animation
      onscrolls.start({ width: "3%" });
    }
  }, [progressInView, onscrolls]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/slider`
        );
        const data = await response.json();
        setSliderData(data.sliderData.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);
  const redirect = () => {
    navigate("/audit");
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);
  return (
    <>
      <div className="slider">
        <div className="slides-container">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
            >
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${slide.avatar}`}
                className="homesliderimg"
                alt={`Slide ${index}`}
              />
              <div className="content-container">
                <motion.h1
                  initial={{ opacity: 0, x: "100%" }}
                  animate={
                    index === currentSlide
                      ? { opacity: 1, x: 0 }
                      : { opacity: 0, x: "100%" }
                  }
                  transition={{ duration: 1 }}
                  className="text-white"
                >
                  {slide.title}
                </motion.h1>
                <motion.h5
                  initial={{ opacity: 0, x: "100%" }}
                  animate={
                    index === currentSlide
                      ? { opacity: 1, x: 0 }
                      : { opacity: 0, x: "100%" }
                  }
                  transition={{ duration: 1 }}
                  className="subtitle text-white"
                  style={{ borderBottom: "1px solid yellow" }}
                >
                  {slide.sub_title}
                </motion.h5>
                <motion.p
                  initial={{ opacity: 0, y: 20 }} // Start from a slightly lower position (y: 20)
                  animate={
                    index === currentSlide
                      ? { opacity: 1, y: 0 }
                      : { opacity: 0, y: 20 }
                  } // Move up and fade in if current slide, otherwise move down and fade out
                  transition={{ duration: 1 }}
                  className="text text-white"
                >
                  {slide.text}
                </motion.p>
              </div>
            </div>
          ))}
        </div>
        <div className="bullets">
          {slides.map((slide, index) => (
            <span
              key={index}
              className={`bullet ${index === currentSlide ? "active" : ""}`}
              onClick={() => setCurrentSlide(index)}
            ></span>
          ))}
        </div>
      </div>

      {/* About section */}
      <Zoom cascade duration={2000}>
        <div className="container mt-5 pt-5">
          <h3 className="about-heading pb-2">About our projects</h3>

          <h6 className="mt-3 mb-3">
            Aghaz-e-Safar Foundation has provided financial, medical,
            educational and every neccessary help to needy people{" "}
          </h6>
          <Zoom right duration={2000}>
            <a href="/projects" className="mt-5 about-link-button mb-4">
              know more <ArrowForwardIcon />
            </a>
          </Zoom>
        </div>
      </Zoom>
      {/* **************Feature**************** */}
      <div className="container mt-5 pt-3 ">
        <Zoom left duration={2000}>
          <h2 className="featured-heading text-center">
            our featured projects
          </h2>
        </Zoom>
      </div>
      <div className="container-fluid fund-back mb-5 pb-5 ">
        <div className=" container row ">
          <div className="col-12 col-sm-12 col-md-6  text-center pt-4 ps-5">
            <Zoom left duration={2000}>
              {" "}
              <img
                className="img-fluid donate-img pb-5"
                src={donate}
                alt="donate"
              />
            </Zoom>
          </div>
          <div className="col-12 col-sm-12 col-md-6  ">
            <h2 className="education-funds  pb-3">Education Funds</h2>
            <h5 className="mt-2 funds-text">funds collected</h5>
            <div className="mt-4 progress-bar">
              <motion.span
                ref={progresGreensRef}
                className="progress-green"
                initial={{ width: 0 }}
                animate={onscrollgreen}
                transition={{ duration: 1 }}
              ></motion.span>
            </div>
            <div className="mt-5">
              <Link className="donate-btn " to="/donate-us">
                <FontAwesomeIcon icon={faCircleDollarToSlot} /> Donate now
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ width: "90%", marginLeft: "5%", marginBottom: "3rem" }} />
      {/* *****************Ambulance********************* */}
      <div className="container-fluid fund-back mb-5 pb-5">
        <div className=" container row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 pt-4 ps-5">
            <Zoom left duration={2000}>
              {" "}
              <img
                className="img-fluid donate-img pb-5"
                src={ambulance}
                alt="donate"
              />
            </Zoom>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">
            <h2 className="education-funds  pb-3">Ambulance Funds</h2>
            <h5 className="mt-2 funds-text">funds collected</h5>
            <div className="mt-4 progress-bar">
              <motion.span
                ref={progressRef}
                className="progress-red"
                initial={{ width: 0 }}
                animate={onscrolls}
                transition={{ duration: 1 }}
              ></motion.span>
            </div>
            <div className="mt-5">
              <Link to="/donate-us" className="donate-btn">
                <FontAwesomeIcon icon={faCircleDollarToSlot} /> Donate now
              </Link>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ width: "90%", marginLeft: "5%", marginBottom: "3rem" }} />
      {/* *****Register In************** */}
      <div className="container-fluid">
        <Zoom cascade duration={2000}>
          <h1 className="register-heading mb-5">Registered In:</h1>
        </Zoom>
        <div className="row">
          <Zoom bottom duration={1500}>
            <div
              className="col-12 col-sm-6 text-center mb-4 "
              onClick={redirect}
              style={{ cursor: "pointer" }}
            >
              <img
                className="img-fluid cer-images"
                src={punjab}
                alt=""
                style={{ width: "100%", maxWidth: "10rem" }}
              />
              <div className="text-center mt-2">
                Certificate Of Registration
              </div>
            </div>
          </Zoom>
          <Zoom bottom duration={1500}>
            <div
              className="col-12 col-sm-6 text-center mb-4 "
              onClick={redirect}
              style={{ cursor: "pointer" }}
            >
              <img
                className="img-fluid cer-images"
                src={city}
                alt=""
                style={{ width: "100%", maxWidth: "10rem" }}
              />
              <div className="text-center mt-2">
                Certificate Of Registration
              </div>
            </div>
          </Zoom>
        </div>
      </div>
      <hr style={{ width: "90%", marginLeft: "5%", marginBottom: "3rem" }} />
      {/* **************become a member******************* */}
      <div>
        <Zoom cascade duration={2000}>
          <h1 className="register-heading mb-4 ms-5">become a member !</h1>
        </Zoom>
      </div>
      <div className="row w-100">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <Zoom cascade duration={2000}>
            <p className="ms-5">
              Abu Hurairah (رضي الله عنه) narrated that the Messenger of Allah
              (ﷺ) said: “Whoever relieves a Muslim of a burden from the burdens
              of the world, Allah will relieve him of a burden from the burdens
              on the Day of Judgement. And whoever helps ease a difficulty in
              the world, Allah will grant him ease from a difficulty in the
              world and in the Hereafter. And whoever covers (the faults of) a
              Muslim, Allah will cover (his faults) for him in the world and the
              Hereafter. And Allah is engaged in helping the worshipper as long
              as the worshipper is engaged in helping his brother.”
            </p>
          </Zoom>
          <Zoom bottom duration={2000}>
            <Link to="/join-us" className="joinus-btn mt-5 ms-5">
              join us
            </Link>
          </Zoom>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <Zoom bottom duration={2000}>
            <img className="img-fluid unity-img" src={unity} alt="" />
          </Zoom>
        </div>
      </div>

      <hr style={{ width: "90%", marginLeft: "5%" }} />
      {/* *******************Message******************** */}
      <Zoom cascade duration={2000}>
        <h1
          className="text-center "
          style={{ fontWeight: "700", textTransform: "uppercase" }}
        >
          Message
        </h1>
      </Zoom>
      <OurTeam />
      {/*<Footer/> */}
    </>
  );
};

export default AutoSlider;
