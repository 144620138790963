import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./project.css";
import logo from "../assets/Logo.png";
import Zoom from "react-reveal/Zoom";

const Project = ({ cardsPerPage }) => {
  const [ourteamData, setOurteamData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const cardsContainerRef = useRef(null);
  const startIndex = (currentPage - 1) * cardsPerPage;
  useEffect(() => {
    const fetchData = async (page) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/projectsinfo?page=${page}&limit=${cardsPerPage}`
        );
        const data = await response.json();
        setOurteamData(data.MeetingsData);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData(currentPage);
  }, [currentPage, cardsPerPage]);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
          observer.unobserve(entry.target);
        }
      });
    }, options);
    const cards =
      cardsContainerRef.current.querySelectorAll(".projectcard-div");
    cards.forEach((card) => {
      observer.observe(card);
    });
    return () => {
      observer.disconnect();
    };
  }, []);
  const searchHandle = async (event) => {
    const searchValue = event.target.value;
    if (searchValue) {
      try {
        let result = await fetch(
          `${process.env.REACT_APP_BASE_URL}/projectsinfo/search/${searchValue}`
        );
        result = await result.json();
        if (result) {
          setOurteamData(result);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/projectsinfo`
        );
        const data = await response.json();
        setOurteamData(data.MeetingsData.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  return (
    <>
      <div style={{ position: "relative", overflow: "hidden", margin: "0px" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <Zoom cascade duration={2000}>
          <h1 className="text-center text-white w-100 dwonload-text">
            projects
          </h1>
        </Zoom>
      </div>
      {/* *******************Project Main******************
       ************************************************* */}
      <div>
        <div className="text-center m-5 search-container">
          <input
            type="input"
            className="search-input"
            placeholder="Search by unit name like(lahore)..."
            onChange={searchHandle}
          />
          <span className="search-icon">&#128269;</span>
        </div>
        <ul
          className="mt-5 mb-5 project-ul text-center"
          ref={cardsContainerRef}
        >
          {Array.isArray(ourteamData) && ourteamData.length > 0 ? (
            ourteamData.map((data, index) => {
              const actualIndex = startIndex + index;
              return (
                <Zoom key={actualIndex} cascade duration={1000}>
                  <div className="card projectcard-div project-div">
                    <Link to={`/projectSlider/${actualIndex}`}>
                      <div
                        class="card  card-head-img text-center"
                        style={{
                          width: "18rem",
                          height: "17rem",
                          position: "relative",
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
                          class="card-img-top"
                          alt="..."
                          style={{
                            height: "100%",
                            filter: "brightness(0.7)",
                            width: "100%",
                          }}
                        />
                        <p
                          style={{
                            position: "absolute",
                            top: "0px",
                            background: "green",
                            color: "white",
                            borderRadius: "6px",
                            padding: "10px",
                          }}
                        >
                          {data.unit}
                        </p>
                        <div class="card-body card-content-title">
                          <h6 class="card-title">{data.title}</h6>
                          <span
                            class="card-text"
                            style={{ borderTop: "2px solid yellow" }}
                          >
                            {data.text}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Zoom>
              );
            })
          ) : (
            <>
              <h1 className="text-center">No Result Found</h1>
            </>
          )}
        </ul>
      </div>
      <ul className="pagination mt-5 d-flex justify-content-center">
        {Array.from({ length: totalPages }).map((_, index) => (
          <li
            key={index}
            className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
          >
            <button
              className={`page-link ${
                currentPage === index + 1 ? "active-btn" : ""
              }`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Project;
