import React, { useState, useEffect } from "react";
import "./futureProjects.css";
import logo from "../assets/Logo.png";
import Zoom from "react-reveal/Zoom";
const FutureProjects = () => {
  const [ourteamData, setOurteamData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/futureplains`
        );
        const data = await response.json();
        // Reverse the order of fetched data
        setOurteamData(data.sliderData.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []); // No need to include [0] here, just an empty dependency array
  return (
    <>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <div className="text-center maindownloaddiv">
          <img src={logo} alt="logo" style={{ height: "25%", width: "25%" }} />
        </div>
        <svg
          className="tri-img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            class="elementor-shape-fill"
            d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
            fill="#FFFFFF"
          ></path>
        </svg>
        <div className="text-center ">
          <Zoom cascade duration={2000}>
            <h1 className="text-center w-100 text-white dwonload-text">
              future plans
            </h1>
          </Zoom>
        </div>
      </div>
      <div className="container mt-1 ">
        {ourteamData.map((data, index) => (
          <Zoom key={index} cascade duration={2000}>
            <div className=" future-main-div text-center w-100">
              <img
                className="future-images text-center "
                src={`${process.env.REACT_APP_BASE_URL}/${data.avatar}`}
              />
              <h2 style={{ textTransform: "uppercase" }}>{data.title}</h2>
              <p>{data.text}</p>
            </div>
          </Zoom>
        ))}
      </div>
    </>
  );
};

export default FutureProjects;
