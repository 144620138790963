import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../assets/Logo.png";
const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const handleToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const getActiveClass = (path) => {
    return window.location.pathname === path ? "active-link" : "";
  };
  const getActiveButtonClass = (path, isActive) => {
    return isActive ? "active-link-button" : "";
  };
  return (
    <>
      <div className="above-navbar">
        <div className="logo-animate-div">
          <img src={logo} style={{ width: "7rem", height: "7rem" }} />
        </div>
        <div style={{ position: "relative" }}>
          <div className="title__header">
            <h5>AGHAZ-E-SAFAR FOUNDATION®</h5>
          </div>
          <span className="logo-name-aghaz">
            <h3>®آغازِ سفر فاؤنڈیشن</h3>
          </span>
        </div>
      </div>
      <nav
        className="navbar navbar-border navbar-expand-lg navbar-dark  navbar-container"
        style={{ background: "black" }}
      >
        <div className="container d-flex justify-content-center">
          <button
            className={`navbar-toggler ${isMenuOpen ? "active" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={handleToggle}
            style={{ border: "2px solid #333" }}
          >
            <span className="navbar-toggler-icon text-white">
              <MenuIcon />
            </span>
            <span className="navbar-toggler-cross text-white">
              <CloseIcon />
            </span>
          </button>
          <div
            className={`collapse navbar-collapse navcontent ${
              isMenuOpen ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-white text-center">
              <li class="nav-item float-right">
                <a
                  className={`nav-link ${getActiveClass("/")}`}
                  aria-current="page"
                  href="/"
                >
                  Home
                </a>
              </li>

              <li class="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${
                    getActiveClass("/main-idea") ||
                    getActiveClass("/ourteam") ||
                    getActiveClass("/contactus") ||
                    getActiveClass("/suggestion")
                      ? "active-link"
                      : ""
                  }`}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </a>
                <ul
                  className="dropdown-menu bg-dark "
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/main-idea"
                    >
                      Our Idea
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/ourteam"
                    >
                      Our Team
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/contactus"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/suggestion"
                    >
                      Any Suggestion
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${
                    getActiveClass("/funds-lahore") ||
                    getActiveClass("/funds-rawalpindi")
                      ? "active-link"
                      : ""
                  }`}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Funds
                </a>
                <ul
                  class="dropdown-menu bg-dark"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/funds-lahore"
                    >
                      Funds Position Of Lahore
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/funds-rawalpindi"
                    >
                      Funds Position Of Rawalpindi
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <a
                  className={`nav-link ${getActiveClass("/meetings")}`}
                  aria-current="page"
                  href="/meetings"
                >
                  Meetings
                </a>
              </li>
              <li class="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${
                    getActiveClass("/performance-lahore") ||
                    getActiveClass("/audit")
                      ? "active-link"
                      : ""
                  }`}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Reports
                </a>
                <ul
                  class="dropdown-menu bg-dark"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/performance-lahore"
                    >
                      Performance Report
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item text-light dropchild" href="/audit">
                      Certificates & Audit Reports
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  className={`nav-link dropdown-toggle ${
                    getActiveClass("/projects") ||
                    getActiveClass("/future-projct")
                      ? "active-link"
                      : ""
                  }`}
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Projects
                </a>
                <ul
                  class="dropdown-menu bg-dark"
                  aria-labelledby="navbarDropdown"
                >
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/projects"
                    >
                      Current Projects
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item text-light dropchild"
                      href="/future-projct"
                    >
                      Future Projects
                    </a>
                  </li>
                </ul>
              </li>
              <Link
                to="/download-app"
                className={`navbtn text-center ${getActiveButtonClass(
                  "/download-app",
                  activeButton === "/download-app"
                )}`}
                id="navbarDropdown"
                onClick={() => {
                  setMenuOpen(false); // Close the menu
                  setActiveButton("/download-app"); // Set the active button
                }}
              >
                Download our app
              </Link>

              <Link
                to="/donate-us"
                className={`navbtn text-center ${getActiveButtonClass(
                  "/donate-us",
                  activeButton === "/donate-us"
                )}`}
                id="navbarDropdown"
                onClick={() => {
                  setMenuOpen(false);
                  setActiveButton("/donate-us");
                }}
              >
                Donate Us
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
