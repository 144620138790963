import React from "react";
import "./aboutus.css";
import logo from "../assets/Logo.png";
import Zoom from "react-reveal/Zoom";

const Aboutus = () => {
  return (
    <>
      <div className="container-fluid back-color">
        <div className="container back-color bg-dark">
          <div className="row text-center">
            <Zoom cascade duration={2000}>
              <div className="col-sm-12 col-md-6 col-lg-6 col-12 ps-5 ">
                <h1 className="head-text text-start">
                  prupose of this foundation
                </h1>
                <p className="text-start para-text">
                  "improving the lives of next generation"
                </p>
                <h1 className="head-text text-start">
                  mission of this foundation
                </h1>
                <p className="text-start para-text">
                  "the development and prosperity of the people through
                  education and awareness,<br></br>
                  raising awareness, especially for wwomen's rights and hygiene"
                </p>
                <h1 className="head-text text-start">
                  message this foundation
                </h1>
                <p className="text-start para-text">
                  "education, women's right, youth development, health,<br></br>{" "}
                  law and order"
                </p>
              </div>
            </Zoom>

            <div className="col-sm-12 col-md-6 col-lg-6 col-12">
              <Zoom cascade duration={2000}>
                <img
                  className="img-fluid"
                  src={logo}
                  alt=""
                  style={{ height: "auto", width: "500px" }}
                />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
